import React, { FC, useEffect, useRef, useState } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Room from '../../Components/Room/Room'
import { useAppDispatch, useAppSelector } from '../../Reducers/hooks'
import { startConversationAsync } from '../../Reducers/socketSlice'
import { MenuPages, switchMenuPage, toggleMenu } from '../Menu/MenuSlice'
import { updateDisabledPopups, updateIsMultiplayerRoom, updateIsTraverseRoom } from './RoomSlice'
import { PopupDialog, TransitionDialog } from '../../Common/common-index'
import { fetchPreferences } from '../../Reducers/preferencesSlice'
import { useLocation } from 'react-router-dom'
import {
  contentService,
  BadgeEventMessage,
  messagingService,
  MessagingTopics,
  SceneEventMessage,
  eventService,
  localStorageService,
  Preference,
  DisabledPopups,
  authService,
} from '../../Services/services-index'
import { ToolButtons } from '../../Components/ToolButtons/ToolButtons'
import {
  updateExpandViewActive,
  updateCurrentUserButtonState,
  updateAllowedButtonState,
  updateUserListState,
  triggerMuteAll,
  setAvatar,
  setProfilePictureUpdate,
  updateActiveVoiceRegion,
  triggerMuteUser,
} from '../ToolButtonsContainer/ToolButtonSlice'
import { PassportPages, switchPassportPage } from '../PassportContainer/PassportBadgesSlice'
import RoomInstancesDialog from '../../Common/Components/RoomInstancesDialog/RoomInstancesDialog'
import { getRoomInstances } from '../../Services/Services/w3ds.service'
import Config from '../../config.json'

interface Payload {
  html: any
  mediaSrcOrPath: any
  mediaType: any
  optOut: any
  image?: any
  optOutContentId?: any
}

const useStyles = makeStyles(() =>
  createStyles({
    item: {
      width: '100%',
      height: '100%',
    },
    container: {
      boxShadow: 'none',
      position: 'absolute',
      left: 10,
      top: 20,
      '&:active': { boxShadow: 'none', transition: 'none' },
    },
  })
)

function useQuery() {
  return new URLSearchParams(decodeURI(useLocation().search))
}

const RoomContainer: FC = () => {
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const room = useAppSelector((state) => state.room.room)
  const currentUser = useAppSelector((state) => state.auth.currentUser)
  const preferences = useAppSelector((state) => state.preferences.specificPreferences)
  const socket = useAppSelector((state) => state.socket.socket)
  const roomId = useAppSelector((state) => state.room.activeRoomId)
  const refreshTrigger = useAppSelector((state) => state.room.roomRefreshTrigger)

  const roomContent = useAppSelector((state) => state.room.roomContent)
  const disabledPopups = useAppSelector((state) => state.room.disabledPopups)
  const isExpandViewActive = useAppSelector((state) => state.toolButtons.isExpandViewActive)
  const currentUserToolState = useAppSelector((state) => state.toolButtons.currentUser)
  const selectedAvatar = useAppSelector((state) => state.toolButtons.selectedAvatar)
  const profilePictureUpdate = useAppSelector((state) => state.toolButtons.profilePictureUpdate)
  const backgroundMusicOn = useAppSelector((state) => state.settings.backgroundMusicOn)
  const thirdPersonViewOn = useAppSelector((state) => state.settings.thirdPersonView)
  const muteAll = useAppSelector((state) => state.toolButtons.muteAll)
  const muteUser = useAppSelector((state) => state.toolButtons.muteUser)
  const callState = useAppSelector((state) => state.call.callStarted)
  const iframeRef = React.createRef<HTMLIFrameElement>()
  const [iframeWin, setIframeWin] = useState<any>()
  const [iframe, setIframe] = useState<any>()
  const [colorObj, setColorObj] = useState<any>()
  const [popupOpen, setPopupOpen] = useState(false)
  const [popupPayload, setPopupPayload] = useState<any>()
  const [randomString, setRandomString] = useState<number>()
  const [transitionUrl, setTransitionUrl] = useState<any>()
  const [enteredInRoom, setEnteredInRoom] = useState(false)
  const [showTransitionContent, setShowTransitionContent] = useState<boolean>(false)
  const [userhasLoudSpeakerAccess, setUserhasLoudSpeakerAccess] = useState<boolean>(false)
  const [, setStateRefreshTrigger] = useState(0)
  const [showMultiplayerControllers, setShowMultiplayerControllers] = useState<boolean>(false)
  const [transitonVideoPreference, setTransitonVideoPreference] = useState<Preference | undefined>()
  const [useEventAvatars, setUseEventAvatars] = useState<Preference | undefined>()
  const [userSelectedAvatar, setUserSelectedAvatar] = useState<Preference | undefined>()
  const [hideProfilePicture, setHideProfilePicture] = useState<Preference | undefined>()
  const [enableTokenWallet, setEnableTokenWallet] = useState<Preference | undefined>()
  const [voiceReach, setVoiceReach] = useState<Preference | undefined>()
  const [localDisabledPopups, setLocalDisabledPopups] = useState<DisabledPopups[]>()
  const [hasPrivateCall, setHasPrivateCall] = useState<boolean>(false)
  const [spaceLoaded, setSpaceLoaded] = useState<boolean>(false)
  const [useInvisitHotspot, setUseInvisitHotspot] = useState<Preference | undefined>()
  const [initiatorInProgress, setInitiatorInProgress] = useState<boolean>(false)
  const [isTraverseRoom, setIsTraverseRoom] = useState<boolean>(false)
  const [showRoomInstancesDialog, setShowRoomInstancesDialog] = useState<boolean>(false)
  const [isShowRoomInstancesDialogSet, setIsShowRoomInstancesDialogSet] = useState<boolean>(false)
  const [selectedRoomInstance, setSelectedRoomInstace] = useState<number>()
  const [roomInstances, setRoomInstances] = useState<any>()
  const [isErrorInstances, setIsErrorInstances] = useState<boolean>(false)
  const query = useQuery()
  const roomContentRef = useRef(roomContent)
  const [isRoomChatEnabled, setIsRoomChatEnabled] = useState<Preference | undefined>()
  let baseUrl: any

  /**
   * Load Lib Jitsi Meet since it is environment specific
   */
  const externalScript = document.createElement('script')

  externalScript.setAttribute('src', `https://${Config.VSF_K8S_JITSI_HOST}/external_api.js`)
  document.head.appendChild(externalScript)

  useEffect(() => {
    dispatch(fetchPreferences({ type: 'specific', room: roomId }))
  }, [roomId])

  useEffect(() => {
    if (preferences) {
      setColorObj({
        uiPrimaryColor: preferences['color-ui-primary'] || '',
        uiSecondaryColor: preferences['color-ui-secondary'] || '',
        uiAccentPrimaryColor: preferences['color-ui-accent-primary'] || '',
        bgPrimaryColor: preferences['color-bg-primary'] || '',
        bgSecondaryColor: preferences['color-bg-secondary'] || '',
        labelBackgroundColor: preferences['label_background_color'] || '',
        labelDarkBackgroundColor: preferences['label_dark_background_color'] || '',
        labelFontBold: preferences['label_font_bold'] || '',
        labelFontColor: preferences['label_font_color'] || '',
        labelOpacity: preferences['label_opacity'] || '',
      })

      // Collect transition content video preference and url
      setTransitonVideoPreference(preferences['transition_content'])
      setUseEventAvatars(preferences['use_event_avatars'])
      setUserSelectedAvatar(preferences['user_selected_avatar'])
      setHideProfilePicture(preferences['hide_profile_picture'])
      setEnableTokenWallet(preferences['enable_token_wallet'])
      setVoiceReach(preferences['voice_reach'])
      setUseInvisitHotspot(preferences['use_invisit_hotspot'])
      setIsRoomChatEnabled(preferences['room_chat'])
    }
  }, [preferences])

  const getMeetingId = () => {
    let id
    if (room?.link) {
      const expression = /meeting\/(\d+)/
      const match = expression.exec(room.link)
      if (match) {
        id = parseInt(match[1])
      }
    }
    return id
  }

  //Check if user should see the dialog for selecting room instance
  useEffect(() => {
    if (room?.id) {
      const shouldShowRoomInstancesDialog = async () => {
        const meetingId = getMeetingId()
        if (isPresenterOrUp() && meetingId) {
          try {
            const res = await getRoomInstances(meetingId, currentUser?.breakout_group)
            if (res.status === 200 && res?.data?.instances?.length > 1) {
              setRoomInstances(res.data)
              setShowRoomInstancesDialog(true)
            }
          } catch (error: any) {
            console.log(error)
            setIsErrorInstances(true)
          }
        }
        setIsShowRoomInstancesDialogSet(true)
      }
      shouldShowRoomInstancesDialog()
    }
  }, [room])

  //Set iframe if user is not supposed to see the dialog or if an instance was already selected
  useEffect(() => {
    if (isShowRoomInstancesDialogSet && !showRoomInstancesDialog) {
      setIframeWin(iframeRef.current?.contentWindow)
      setIframe(iframeRef.current)
    }
  }, [showRoomInstancesDialog, isShowRoomInstancesDialogSet])

  // To load room and connect with W3DS
  useEffect(() => {
    if (iframe) {
      getTransitionUrl()
      loadRoom()
    }
  }, [iframe, refreshTrigger, socket])

  // Check transitionContent local store value
  useEffect(() => {
    const transitionContentState: any =
      localStorageService.getLocalStorageItemValue('transitionContent')
    if (transitionContentState) {
      transitionContentState === 'true'
        ? setShowTransitionContent(true)
        : setShowTransitionContent(false)
    }
  }, [])

  useEffect(() => {}, [transitionUrl, transitonVideoPreference])
  // randomizing the iframe request so it doesn't get cached by the browser
  useEffect(() => {
    if (!randomString) {
      setRandomString(Date.now())
    }
  })

  useEffect(() => {
    if (currentUser && roomId) {
      const adminRole = currentUser?.roles?.filter((role) => role?.role.toString() === 'admin')
      adminRole ? setUserhasLoudSpeakerAccess(true) : setUserhasLoudSpeakerAccess(false)
    }
  }, [userhasLoudSpeakerAccess])

  useEffect(() => {}, [enteredInRoom, isExpandViewActive, currentUserToolState])

  useEffect(() => {
    if (spaceLoaded) {
      const backgroundMusicStatus: any =
        localStorageService.getLocalStorageItemValue('backgroundMusicStatus')
      if (backgroundMusicStatus) {
        backgroundMusicStatus === 'true'
          ? backgroundMusicToggle(true)
          : backgroundMusicToggle(false)
      }
    }
  }, [spaceLoaded, backgroundMusicOn])

  useEffect(() => {
    if (spaceLoaded) {
      const thirdPersonViewStatus: any =
        localStorageService.getLocalStorageItemValue('thirdPersonView')
      if (thirdPersonViewStatus === 'true') {
        onToggleThirdPerson(thirdPersonViewOn)
      }
    }
  }, [spaceLoaded])

  useEffect(() => {
    if (spaceLoaded) {
      onToggleThirdPerson(thirdPersonViewOn)
    }
  }, [thirdPersonViewOn])

  useEffect(() => {
    setStateRefreshTrigger(refreshTrigger)
    setRandomString(Date.now())
  }, [refreshTrigger])

  useEffect(() => {
    if (muteAll) {
      onMuteAllParticipants()
    }
  }, [muteAll])

  useEffect(() => {
    if (muteUser) {
      postUserAction('muteUser', { nid: muteUser })
      dispatch(triggerMuteUser(null))
    }
  }, [muteUser])

  // Validate to display Multiplayer controllers (Mic/Vedio buttons)
  useEffect(() => {
    if (room && room?.link !== null) {
      const meetingCheck = room.link.includes('meeting')
      if (meetingCheck) {
        dispatch(updateIsMultiplayerRoom(true))
        setShowMultiplayerControllers(true)
      } else {
        dispatch(updateIsMultiplayerRoom(false))
        setShowMultiplayerControllers(false)
      }
    }
  }, [room])

  useEffect(() => {
    if (room && room?.link !== null) {
      const meetingCheck = room.link.includes('traverse')
      if (meetingCheck) {
        dispatch(updateIsTraverseRoom(true))
        setIsTraverseRoom(true)
      } else {
        dispatch(updateIsTraverseRoom(false))
        setIsTraverseRoom(false)
      }
    }
  }, [room])

  // Collect disabledPopUp id's from localstore
  useEffect(() => {
    const localValues: any = localStorageService.getLocalStorageItemValue('disabledPopups')
    if (localValues) {
      setLocalDisabledPopups(JSON.parse(localValues))
    }
  }, [disabledPopups])

  useEffect(() => {
    if (!(!hasPrivateCall && callState === null)) {
      onHasPrivateCallChange(!hasPrivateCall)
      setHasPrivateCall(!hasPrivateCall)
    }
  }, [callState])

  useEffect(() => {
    roomContentRef.current = roomContent
  }, [roomContent])

  useEffect(() => {
    if (iframe && iframeWin && spaceLoaded && voiceReach?.value) {
      iframeWin.postMessage(
        {
          voiceReach: voiceReach.value,
        },
        iframe.src
      )
    }
  }, [voiceReach, spaceLoaded])

  useEffect(() => {
    if (iframe && iframeWin) {
      iframeWin.postMessage(
        {
          userSelectedAvatar: selectedAvatar,
        },
        iframe.src
      )
    }
  }, [selectedAvatar])

  useEffect(() => {
    if (iframe && iframeWin && profilePictureUpdate) {
      iframeWin.postMessage(
        {
          profilePictureUpdate: true,
        },
        iframe.src
      )
      dispatch(setProfilePictureUpdate(false))
    }
  }, [profilePictureUpdate])

  const postMessageToContentWindow = () => {
    baseUrl = String(room?.link).match(/^https?:\/\/[^#?\/]+/) // get the baseurl
    if (iframe && iframeWin && currentUser && roomId) {
      iframe.onload = () => {
        baseUrl && window.addEventListener('message', handleReceiveMessage, false)
        //Sending handshake message
        iframeWin.postMessage(
          {
            isReady: true,
          },
          iframe.src
        )
      }
    }
  }

  const initiatorPostMessage = async (
    useOAuth: boolean,
    oauthData: {
      client_id?: string
      state?: string
    }
  ) => {
    if (currentUser) {
      const hasPresenterRoleOrUp = isPresenterOrUp()

      const authMessagePart: any = {}
      if (useOAuth && oauthData.state) {
        const authCodeResponse: any = await authService.authCode(
          { client_challenge: oauthData.state },
          { Authorization: `Bearer ' + ${currentUser.jwt_token}` }
        )
        authMessagePart.auth_code = authCodeResponse.data.auth_code
      } else {
        authMessagePart.auth = currentUser.jwt_token
      }

      iframeWin.postMessage(
        {
          ...authMessagePart,
          color: colorObj.uiPrimaryColor?.value || undefined,
          userId: currentUser.id,
          hasPresenterRoleOrUp,
          colorObj,
          externalId: currentUser.event_code,
          useExternalAvatars: useEventAvatars?.value === 'yes',
          userSelectedAvatar: userSelectedAvatar?.value,
          hideProfilePicture: hideProfilePicture ? hideProfilePicture.value === 'yes' : false,
          voiceReach: voiceReach?.value,
          selectedRoomInstance,
          enable_token_wallet: enableTokenWallet,
          use_invisit_hotspot: useInvisitHotspot,
          room_chat: isRoomChatEnabled?.value === 'yes',
        },
        iframe.src
      )
      //pass command when the space has loaded
      query.forEach((parameterValue, parameterKey) => {
        const payload = {
          key: parameterKey,
          value: parameterValue,
        }
        sendPostMessageCommand(payload)
      })
      setSpaceLoaded(true)
      setInitiatorInProgress(false)
    }
  }

  const isPresenterOrUp = () => {
    let hasPresenterRoleOrUp = false
    if (currentUser && roomId) {
      currentUser.roles?.forEach((role) => {
        const userRoleName = role?.role
        if (
          (userRoleName === 'presenter' && role?.room_id === roomId) ||
          userRoleName === 'admin' ||
          userRoleName === 'event-admin'
        ) {
          hasPresenterRoleOrUp = true
        }
      })
    }
    return hasPresenterRoleOrUp
  }

  const isEventAdmin = () => {
    return currentUser?.roles?.some((role) => role?.role === 'event-admin')
  }

  const handleReceiveMessage = async (event: any) => {
    if (event.origin !== baseUrl[0]) {
      return
    }
    if (event.data?.page === 'achievementShop') {
      dispatch(toggleMenu(true))
      dispatch(switchMenuPage(MenuPages.Passport))
      dispatch(switchPassportPage(PassportPages.RedeemCoinsPage))
    }
    if (event.data?.page === 'roomChat') {
      dispatch(toggleMenu(true))
      dispatch(switchMenuPage(MenuPages.RoomChat))
    }

    //Sending handshake message
    if (event.data.isReady && !initiatorInProgress) {
      setInitiatorInProgress(true)
      initiatorPostMessage(event.data.useOAuth, {
        client_id: event.data.client_id,
        state: event.data.state,
      })
    }

    if (event.data.hide_audio_chat !== undefined && currentUser) {
      dispatch(
        updateAllowedButtonState({
          microphoneAllowed: event.data.hide_audio_chat,
          isEventAdmin: isEventAdmin(),
        })
      )
    }

    if (event.data.hide_mute_all !== undefined && currentUser) {
      dispatch(
        updateAllowedButtonState({
          muteAllAllowed: event.data.hide_mute_all,
          isEventAdmin: isEventAdmin(),
        })
      )
    }

    if (event.data.hide_webcam_feed !== undefined && currentUser) {
      dispatch(
        updateAllowedButtonState({
          videoAllowed: event.data.hide_webcam_feed,
          isEventAdmin: isEventAdmin(),
        })
      )
    }

    // start a converstion with bot/user
    if (event.data.userId) {
      if (
        preferences &&
        preferences['private_chat'] &&
        preferences['private_chat'].value === 'yes'
      ) {
        const payload: any = {
          receiverIds: undefined,
          botId: undefined,
        }
        const receiverId = parseInt(event.data.userId)
        if (receiverId) {
          payload.receiverIds = [receiverId]
        }
        if (event.data.botId) {
          payload.botId = event.data.botId
        }
        dispatch(toggleMenu(true))
        dispatch(switchMenuPage(MenuPages.Chat))
        dispatch(startConversationAsync(payload))
      }
    }

    if (event.data.externalPopupId && roomContentRef) {
      if (roomContentRef.current) {
        const roomPopupData = roomContentRef.current.content.filter(
          (contentObject) => contentObject.render_in_3d
        )

        const popUpDataObject = roomPopupData.filter(
          (popupData) => popupData.id === parseInt(event.data.externalPopupId)
        )

        let popUpAttribute: {
          optOut: any
          enablePopup: any
          achievementAction: any
          onClickSendEmail: any
        }

        let optOut: any
        let contentId: number
        let mediaId = ''
        let file: any
        let enablePopup = ''
        let achievementAction: any
        let onClickSendEmail: any

        if (popUpDataObject.length !== 0) {
          popUpAttribute = popUpDataObject[0].attributes
          optOut = popUpAttribute.optOut
          contentId = popUpDataObject[0].id
          mediaId = popUpDataObject[0].media_id
          file = popUpDataObject[0].file
          enablePopup = popUpAttribute.enablePopup
          achievementAction = popUpAttribute.achievementAction
          onClickSendEmail = popUpAttribute.onClickSendEmail
        }

        // ready to send
        if (enablePopup) {
          const html = enablePopup.replace(/;/g, '∞')
          let options: { imagePath?: any; videoSrc?: any } = {}
          let mediaSrcOrPath = ''
          let mediaType = ''
          let image = ''
          const optOutContentId = popUpDataObject[0].id

          if (mediaId) {
            // Get media source link via content service
            const videoSrcLink = await contentService
              .getMediaById(mediaId)
              .then((response: any) => {
                const srcLink = response?.data?.video[0]?.link
                return srcLink ? srcLink : null
              })
            // Assign link values to payload
            if (videoSrcLink) {
              mediaType = 'video'
              mediaSrcOrPath = videoSrcLink
            }
          } else {
            // If image file is availble
            options = {
              imagePath: file ? file : null,
            }
          }
          if (options.imagePath?.match(/.*\.(jpe?g|png)$/gim)) {
            mediaType = 'image'
            mediaSrcOrPath = options.imagePath
            if (mediaType && mediaType === 'image' && roomId) {
              await contentService.getFiles(mediaSrcOrPath, roomId).then((res) => {
                image = URL.createObjectURL(res.data)
              })
            }
          }

          const payload: Payload = {
            html,
            mediaSrcOrPath,
            image,
            mediaType,
            optOut,
            optOutContentId,
          }
          if (payload) {
            setPopupPayload(payload)
            setPopupOpen(true)
          }
        }

        if (
          achievementAction &&
          contentId! &&
          preferences &&
          preferences['passport_badges']?.value === 'yes'
        ) {
          sendBadgeEventToMessagingSocket(achievementAction, contentId)
        }
        if (onClickSendEmail && contentId!) {
          handleSendEmail(contentId)
        }
      }
    }

    if (event.data.contentStats) {
      const contentStats = event.data.contentStats
      const object = {
        ...contentStats,
      }
      sendSceneEventToMessagingSocket(contentStats.type, contentStats.contentId, object)
    }

    // Get jitsiPayload on event actions
    if (event?.data?.jitsiPayload) {
      dispatch(updateCurrentUserButtonState(event.data.jitsiPayload?.currentUser))
      dispatch(updateUserListState(event.data.jitsiPayload?.userList))
      if (event.data.jitsiPayload.userSelectedAvatar) {
        dispatch(setAvatar(event.data.jitsiPayload?.userSelectedAvatar))
      }
      if (event.data.jitsiPayload.audioRegion) {
        dispatch(updateActiveVoiceRegion(event.data.jitsiPayload?.audioRegion))
      }
      const backgroundMusicStatus: any =
        localStorageService.getLocalStorageItemValue('backgroundMusicStatus')
      const bgState = event?.data?.jitsiPayload?.soundData?.muteBackground
      if (!backgroundMusicStatus?.length && bgState) {
        localStorageService.setLocalStorageItemValue(
          'backgroundMusicStatus',
          JSON.stringify(bgState)
        )
      }
    }
  }

  // Update User actions via Post messages
  const postUserAction = (actionType?: string, data?: Object) => {
    if (iframe && iframeWin) {
      iframeWin.postMessage(
        {
          jitsiEvent: {
            action: actionType,
            soundData: data ? data : undefined,
          },
        },
        iframe.src
      )
    }
  }

  const sendPostMessageCommand = (payload?: Object) => {
    if (iframe && iframeWin) {
      iframeWin.postMessage(
        {
          sendCommand: payload,
        },
        iframe.src
      )
    }
  }

  // Display W3DS controllers if this is not a traverse room
  const renderToolButtons = () => {
    return (
      <>
        {!isTraverseRoom && (
          <div className={classes.container}>
            <ToolButtons
              hasLoudSpeakerAccess={userhasLoudSpeakerAccess}
              onToggleMic={onToggleMic}
              onExpandView={onExpandView}
              onToggleVideo={onToggleVideo}
              onLoadSpeaker={onLoadSpeaker}
            ></ToolButtons>
          </div>
        )}
      </>
    )
  }

  // To Toggle on / off Microphone
  const onToggleMic = () => {
    postUserAction('toggleAudioMute')
    dispatch(
      updateCurrentUserButtonState({
        muted: currentUserToolState?.muted ? false : true,
        videoEnabled: currentUserToolState?.videoEnabled,
      })
    )
  }

  //  To Toggle on / off Loud-speaker
  const onLoadSpeaker = () => {
    postUserAction('setMyVoiceReach')
    dispatch(updateExpandViewActive(!isExpandViewActive))
  }

  // To Toggle on / off Video
  const onToggleVideo = () => {
    postUserAction('toggleVideoMute')
    dispatch(
      updateCurrentUserButtonState({
        videoEnabled: currentUserToolState?.videoEnabled ? false : true,
        muted: currentUserToolState?.muted,
      })
    )
  }

  const onToggleThirdPerson = (thirdPersonView: any) => {
    const payload = {
      key: 'toggleCamera',
      value: thirdPersonView,
    }
    sendPostMessageCommand(payload)
  }

  // To Toggle on / off Background musik
  const backgroundMusicToggle = (value: boolean) => {
    postUserAction('toggleBgMusic', { toggleMusic: value })
  }

  // To Mute all participants
  const onMuteAllParticipants = () => {
    postUserAction('muteAllParticipants')
    dispatch(triggerMuteAll())
  }

  const onHasPrivateCallChange = (value: boolean) => {
    postUserAction('hasPrivateCall', { hasPrivateCall: value })
  }

  // To show mic and other options
  const onExpandView = () => {
    dispatch(updateExpandViewActive(!isExpandViewActive))
  }

  // Display optPopUp
  const renderPopupDialog = (payload: Payload) => {
    if (!localDisabledPopups || isTraverseRoom) {
      return
    }

    const selectedPopUp = localDisabledPopups?.filter(
      (popup: DisabledPopups) => popup.id === payload?.optOutContentId
    )
    if (selectedPopUp[0]?.isDisabled === false || !selectedPopUp?.length) {
      return (
        <PopupDialog
          open={popupOpen}
          image={payload.image}
          html={payload.html}
          mediaSrcOrPath={payload.mediaSrcOrPath}
          mediaType={payload.mediaType}
          optOut={payload.optOut}
          onDisablePopup={() => onDisablePopup(payload?.optOutContentId)}
          onClose={() => setPopupOpen(!popupOpen)}
        ></PopupDialog>
      )
    } else {
      return
    }
  }

  // Disable OptPopUps
  const onDisablePopup = (contentId: number) => {
    const addPopUpContentId = { id: contentId, isDisabled: true }
    dispatch(updateDisabledPopups(addPopUpContentId))
    if (localDisabledPopups) {
      const newValues = [...localDisabledPopups, addPopUpContentId]
      localStorageService.setLocalStorageItemValue('disabledPopups', JSON.stringify(newValues))
    }
  }

  const sendBadgeEventToMessagingSocket = (achievementAttribute: string, contentId: number) => {
    const currentUserId = currentUser?.id || null
    const currentEventCode = currentUser?.event_code || null

    const badgeEventMessage: BadgeEventMessage = {
      user_id: currentUserId,
      achievement_attribute: achievementAttribute,
      room_id: !contentId && room ? room.id : null,
      content_id: contentId ? contentId : null,
      event_code: currentEventCode,
    }

    if (socket) {
      messagingService.emitMessagingSocket(
        MessagingTopics.USER_ACHIEVEMENT_EVENTS,
        badgeEventMessage,
        socket
      )
    }
  }

  const sendSceneEventToMessagingSocket = (
    objectEvent: string,
    contentId: number,
    object: Object
  ) => {
    const currentUserId = currentUser?.id || null

    const sceneEventMessage: SceneEventMessage = {
      user_id: currentUserId,
      event: objectEvent,
      room_id: room ? room.id : null,
      content_id: contentId,
      timestamp: new Date().getTime().toString(),
      object: object,
    }
    if (socket) {
      messagingService.emitMessagingSocket(
        MessagingTopics.USER_SCENE_EVENTS,
        sceneEventMessage,
        socket
      )
      messagingService.emitMessagingSocket(MessagingTopics.VIEW_CONTENT, sceneEventMessage, socket)
    }
  }

  const handleSendEmail = (contentId: number) => {
    const emailDataObject = roomContent?.content.filter(
      (contentObject) => contentObject.id === contentId
    )
    if (emailDataObject && emailDataObject?.length > 0) {
      const body = JSON.parse(emailDataObject[0].attributes.onClickSendEmail)
      eventService.createEmail(body)
    }
  }

  const loadRoom = () => {
    setTimeout(() => {
      postMessageToContentWindow()
    })
    if (!enteredInRoom) {
      onRoomMessageListenerAdded()
    }
  }

  const onRoomMessageListenerAdded = () => {
    if (socket && currentUser) {
      setEnteredInRoom(true)
      messagingService.emitMessagingSocket(
        MessagingTopics.USER_ROOM_EVENTS,
        {
          user_id: currentUser?.id,
          event: 'enter_room',
          room_id: roomId,
          timestamp: Date.now(),
        },
        socket
      )
    }
  }

  // To skip or close the transition content
  const onTransitionClose = () => {
    setShowTransitionContent(!showTransitionContent)
    localStorageService.setLocalStorageItemValue('transitionContent', JSON.stringify(false))
  }

  // Get transition url
  const getTransitionUrl = () => {
    if (roomContent) {
      const transitionContent = roomContent.content?.filter(
        (contentObject) => contentObject?.type === 'transition_content'
      )
      const mediaId = transitionContent[0]?.media_id

      if (mediaId) {
        contentService.getMediaById(mediaId).then((response: any) => {
          setTransitionUrl(response?.data?.video[0]?.link)
        })
      }
    }
  }

  // Display the transitions video
  const renderTransitionVideo = () => {
    if (
      !isTraverseRoom &&
      transitionUrl &&
      transitonVideoPreference &&
      (transitonVideoPreference?.value === 'yes' || transitonVideoPreference?.value === 'auto')
    ) {
      return (
        <TransitionDialog
          open={true}
          mediaSrcOrPath={transitionUrl}
          mediaType={'video'}
          onClose={onTransitionClose}
        ></TransitionDialog>
      )
    }
    return
  }

  const renderRoomInstancesDialog = () => {
    return (
      <RoomInstancesDialog
        onConfirm={onConfirmInstance}
        instances={roomInstances.instances}
        maxUsers={roomInstances.maxUsers}
        isError={isErrorInstances}
      />
    )
  }

  const onConfirmInstance = (instance: number) => {
    setSelectedRoomInstace(instance)
    setShowRoomInstancesDialog(false)
  }

  const roomLink = () => {
    let link = room?.link
    const index = link?.indexOf('?')

    // @ts-ignore
    const modifiedLink = link?.slice(0, index) + `?random=${randomString}&` + link?.slice(index + 1)
    // checking if we have other url params or if the url exists
    link
      ? link.includes('?')
        ? (link = modifiedLink)
        : (link = link + `?random=${randomString}`)
      : (link = '')
    return link
  }

  return (
    <div className={classes.item}>
      {/*wait until value for showRoomInstanceDialog is set to see if we should render dialog for choosing room instance or room itself*/}
      {isShowRoomInstancesDialogSet &&
        (showRoomInstancesDialog ? (
          renderRoomInstancesDialog()
        ) : (
          <>
            {showTransitionContent && renderTransitionVideo()}
            {popupOpen && renderPopupDialog(popupPayload)}
            {showMultiplayerControllers && renderToolButtons()}
            {/* Randomizing the source url keeps the browser from caching the iframe's content */}
            <Room roomURL={roomLink()} elementRef={iframeRef}></Room>
          </>
        ))}
    </div>
  )
}

export default RoomContainer
