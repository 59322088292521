import React, { FC, useState, useEffect } from 'react'
import { Icons } from './icons/Icon'
import { getProviders } from '../../../Services/Services/auth.service'
import { FrontendTypes } from '../../../Services/Models/app.model'
import { Divider } from './Divider'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import './login-form.css'
import { authService, localStorageService } from '../../../Services/services-index'
import './social-login-button.css'
import { useTranslation } from 'react-i18next'

export const LoginForm: FC<{ onNewBackgroundImage?: (domain: string) => void }> = ({
  onNewBackgroundImage,
}) => {
  const { domain } = useParams<{ domain: string; realityCode: string }>()
  const { t } = useTranslation()

  const history = useHistory()
  const location: any = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const redirectTo = queryParams.get('redirect_to')
  const { from } = location.state || { from: { pathname: redirectTo ?? '/' } }

  const OAuth2URL = authService.servicePrefix + '/oauth2'

  const [formData, setFormData] = useState({
    email: '',
    password: '',
    domain: domain || '',
    consentPrivacy: false,
    consentCookies: false,
  })
  const [showPassword, setShowPassword] = useState(false)
  const [formError, setFormError] = useState<string | null>(null)
  const [isCheckingCode, setIsCheckingCode] = useState(false)
  const [isValidDomain, setIsValidDomain] = useState<boolean | null>(null)
  const [providers, setProviders] = useState<string[]>([])
  const [isAdminLogin, setIsAdminLogin] = useState(false)
  const [isEmailPasswordShown, setIsEmailPasswordShown] = useState(true)

  const hideLogin =
    isValidDomain === false ||
    isValidDomain === null ||
    formData.consentCookies === false ||
    formData.consentPrivacy === false

  // Simulate backend call
  const checkRealityCode = async (code: string): Promise<boolean> => {
    return new Promise(async (resolve) => {
      try {
        const providers = await getProviders(FrontendTypes.VISITOR, code)
        setIsEmailPasswordShown(providers.data.user_pass_login !== 'no')
        setProviders(providers.data.providers.map((provider: any) => provider.name))
        resolve(true)
      } catch (err) {
        console.error('Error checking Reality Code', err)
        resolve(false)
      }
    })
  }

  const onOauthClick = async (provider: string) => {
    console.log('provider', provider)
    sessionStorage.setItem('eventCode', formData.domain)
    sessionStorage.setItem('provider', provider)
    sessionStorage.setItem('NEW_LOGIN_SYSTEM', 'true')
    if (from.pathname !== '/') {
      sessionStorage.setItem('redirect_to', from.pathname)
    }
    window.location.href = `${OAuth2URL}?frontend=${FrontendTypes.VISITOR}&provider=${provider}`
  }

  // Debounced reality code check
  useEffect(() => {
    if (!formData.domain) {
      setIsValidDomain(null)
      return
    }

    setIsCheckingCode(true)
    setProviders([])

    const timeoutId = setTimeout(async () => {
      try {
        const isValid = await checkRealityCode(formData.domain)
        onNewBackgroundImage?.(formData.domain)
        setIsValidDomain(isValid)
      } catch (err) {
        setIsValidDomain(false)
      } finally {
        setIsCheckingCode(false)
      }
    }, 500) // 500ms debounce

    return () => clearTimeout(timeoutId)
  }, [formData.domain])

  // Update handleSubmit
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setIsCheckingCode(true)

    try {
      const authResult = await authService.signIn({
        email: formData.email,
        password: formData.password,
        event_code: formData.domain,
        frontend: FrontendTypes.VISITOR,
        force_login: true,
      })
      const { user_data: userData } = (authResult as any).data

      if (redirectTo !== null && redirectTo !== '') {
        const redirectToUrl = new URL(redirectTo)
        redirectToUrl.searchParams.append('jwtToken', userData.jwt_token)
        window.location.href = redirectToUrl.toString()
        return
      }

      localStorageService.setLocalStorageItemValue('currentUser', JSON.stringify(userData))
      localStorageService.setLocalStorageItemValue('enableRoomChatFeed', JSON.stringify(true))
      localStorageService.setLocalStorageItemValue('transitionContent', JSON.stringify(true))
      localStorageService.setLocalStorageItemValue('activeUserStatus', 'available')
      localStorageService.setLocalStorageItemValue('disabledPopups', JSON.stringify([]))

      history.replace(from)
      history.push('/lobby')

      // Continue with form submission
      console.log('Form submitted successfully')
    } catch (err) {
      console.log('err', err)
      setFormError('Error checking Reality Code' + err)
    } finally {
      setIsCheckingCode(false)
    }
  }

  const onAdminClick = () => {
    if (isAdminLogin) {
      setIsAdminLogin(false)
      checkRealityCode(formData.domain)
      setProviders([])
      formData.consentCookies = false
      formData.consentPrivacy = false
    } else {
      setIsAdminLogin(true)
      setIsValidDomain(true)
      setProviders(['accenture'])
      formData.consentCookies = true
      formData.consentPrivacy = true
    }
  }

  const labelStyle: React.CSSProperties = {
    display: 'block',
    marginBottom: '8px',
    color: '#ccc',
    fontSize: '14px',
    boxSizing: 'border-box',
  }

  const checkboxContainerStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '12px',
    fontSize: '14px',
    color: '#ccc',
    boxSizing: 'border-box',
  }

  const errorStyle: React.CSSProperties = {
    backgroundColor: 'rgba(220, 38, 38, 0.1)',
    color: '#ef4444',
    padding: '12px',
    borderRadius: '8px',
    marginBottom: '16px',
    fontSize: '14px',
    boxSizing: 'border-box',
  }

  // Style for the status icon
  const statusIconStyle: React.CSSProperties = {
    position: 'absolute',
    right: '10px',
    top: '35px',
    display: 'flex',
    alignItems: 'center',
    width: '20px',
    height: '20px',
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div style={{ position: 'relative' }}>
          <label style={labelStyle}>{t('login.form.labels.domain')}</label>
          <input
            type='text'
            value={formData.domain}
            onChange={(e) => setFormData({ ...formData, domain: e.target.value })}
            className='login-form-input'
            id='login-form-event-code'
            disabled={domain !== undefined && isValidDomain === true}
            required
          />
          <span style={statusIconStyle}>
            {isCheckingCode && <Icons.Spinner />}
            {!isCheckingCode && isValidDomain === true && <Icons.CheckCircle />}
            {!isCheckingCode && isValidDomain === false && <Icons.XCircle />}
          </span>
        </div>
        <div style={checkboxContainerStyle}>
          <input
            type='checkbox'
            checked={formData.consentPrivacy}
            onChange={(e) => setFormData({ ...formData, consentPrivacy: e.target.checked })}
            style={{ marginRight: '8px' }}
            id='privacy-policy-link-checkbox'
          />
          <span>{t('login.form.consent.privacy')}</span>
        </div>
        <div style={checkboxContainerStyle}>
          <input
            type='checkbox'
            checked={formData.consentCookies}
            onChange={(e) => setFormData({ ...formData, consentCookies: e.target.checked })}
            style={{ marginRight: '8px' }}
            id='local-storage-login-checkbox'
          />
          <span>{t('login.form.consent.cookies')}</span>
        </div>

        {isAdminLogin === false && isValidDomain === false && (
          <div style={errorStyle}>
            <strong>❗ {t('login.form.errors.domain.title')}</strong>
            <p style={{ margin: '4px 0 0' }}>{t('login.form.errors.domain.message')}</p>
          </div>
        )}

        {(formData.consentCookies === false || formData.consentPrivacy === false) && (
          <div style={errorStyle}>
            <strong>❗ {t('login.form.errors.terms.title')}</strong>
            <p style={{ margin: '4px 0 0' }}>{t('login.form.errors.terms.message')}</p>
          </div>
        )}

        <div>
          {isEmailPasswordShown && <Divider />}
          {isEmailPasswordShown && (
            <div>
              <div>
                <label style={labelStyle}>{t('login.form.labels.email')}</label>
                <input
                  type='email'
                  value={formData.email}
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                  className='login-form-input'
                  id='login-form-email'
                  placeholder={t('login.form.placeholders.email')}
                  disabled={hideLogin}
                  required
                />
              </div>

              <div style={{ position: 'relative' }}>
                <label style={labelStyle}>{t('login.form.labels.password')}</label>
                <input
                  type={showPassword ? 'text' : 'password'}
                  value={formData.password}
                  onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                  className='login-form-input'
                  id='login-form-password'
                  disabled={hideLogin}
                  required
                />
                <button
                  type='button'
                  onClick={() => setShowPassword(!showPassword)}
                  style={{
                    position: 'absolute',
                    right: '12px',
                    top: '38px',
                    background: 'none',
                    border: 'none',
                    color: '#ccc',
                    cursor: 'pointer',
                  }}
                  id='login-form-show-password'
                >
                  {showPassword ? <Icons.EyeCrossed /> : <Icons.Eye />}
                </button>
              </div>

              {formError && (
                <div style={errorStyle}>
                  <strong>❗ {t('login.form.errors.login.title')}</strong>
                  <p style={{ margin: '4px 0 0' }}>{t('login.form.errors.login.message')}</p>
                </div>
              )}

              <button
                type='submit'
                disabled={hideLogin}
                className='login-button'
                id='login-form-submit'
              >
                {isAdminLogin
                  ? t('login.form.buttons.adminDashboard')
                  : t('login.form.buttons.login')}
              </button>
            </div>
          )}

          <Divider message={t('login.form.dividers.socialLogin')} />

          <div className='social-login-container'>
            {(providers.includes('google') || hideLogin) && (
              <button
                type='button'
                key={'google'}
                className='social-login-button'
                onClick={() => onOauthClick('google')}
                disabled={hideLogin}
              >
                <Icons.Google />
                {t('login.form.socialButtons.google')}
              </button>
            )}
            {(providers.includes('accenture') || hideLogin) && (
              <button
                type='button'
                key={'accenture'}
                className='social-login-button'
                onClick={() => onOauthClick('accenture')}
                disabled={hideLogin}
              >
                <Icons.Accenture />
                {t('login.form.socialButtons.accenture')}
              </button>
            )}
            {(providers.includes('linkedin') || hideLogin) && (
              <button
                type='button'
                key={'linkedin'}
                className='social-login-button'
                onClick={() => onOauthClick('linkedin')}
                disabled={hideLogin}
              >
                <Icons.LinkedIn />
                {t('login.form.socialButtons.linkedin')}
              </button>
            )}
            {(providers.includes('facebook') || hideLogin) && (
              <button
                type='button'
                key={'facebook'}
                className='social-login-button'
                onClick={() => onOauthClick('facebook')}
                disabled={hideLogin}
              >
                <Icons.Facebook />
                {t('login.form.socialButtons.facebook')}
              </button>
            )}
          </div>
          <div
            style={{
              marginTop: '16px',
              fontSize: '12px',
              cursor: 'pointer',
              width: '100%',
              textAlign: 'center',
            }}
            onClick={onAdminClick}
          >
            <p style={{ textDecoration: 'underline' }}>
              {isAdminLogin
                ? t('login.form.adminSwitch.toVisitor')
                : t('login.form.adminSwitch.toAdmin')}
            </p>
          </div>
        </div>
      </form>
    </div>
  )
}
